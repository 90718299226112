// Components
import React, { useState, useEffect } from "react";
import FollowButton from "../../../components/FollowButton/FollowButton";
import { useRowContext } from "../../../../contexts/RowContext";
import classnames from "classnames";
// Services
import DaVinciService from "../../../../services/davinciService";
// Helpers & Constants & Styles
import { DAVINCI_PAGE_MERCHANT } from "../../../../utils/constants";
import { getTokenClient } from "../../../../utils/helpers/cookie";
import { getDailyCouponLimit } from "../../../../services/couponService";
import { getIsFollowingMerchant } from "../../../../services/followerService";
import appConfig from "../../../../appConfig";
import MerchantTag from "../../../components/RowComponents/BannerRow/MerchantTag/MerchantTag";
import { displayedNameFormatted } from "../utils";
import { useWindowSize } from "../../../../utils/hooks/useWindowSize";
import BlankProfileImage from "./common/BlankProfileImage";
import MerchantRating from "./common/MerchantRating";
import MerchantFollower from "./common/MerchantFollower";
import MerchantProducts from "./common/MerchantProducts";
import MerchantEvaluation from "./common/MerchantEvaluation";
import WomanEnt from "./common/WomanEnt";
import { TbackgroundType } from "../helpers/types";
import StickyCoupon from "./common/StickyCoupon";
import RowBackground from "./common/RowBackground";
import { getMerchantTagData, getMerchantTagType } from "../../../../utils/helpers/merchantTag";

const testIdMappings = require("../../../../../conf/testIdMappings.conf");

function McRow({ initialState, styles, filteredProductCount }) {
  const [followerCount, setFollowerCount] = useState(initialState?.data.followerCount);
  const [followButtonClicked, setFallowButtonClicked] = useState(false);
  const [isThereAuthErr, setIsThereAuthErr] = useState(false);

  const { merchantDetail, totalProductCount = 0, merchantRowBackground, backgroundType } = initialState.data;
  const { setIsFollowBtnLoading, setFollowingStatus, setDailyCouponLimit, setCanBeFollowing } = useRowContext();
  const davinci = DaVinciService(merchantDetail, DAVINCI_PAGE_MERCHANT);
  const isWomenEnt = merchantDetail?.tagList?.some(i =>
    ["woman-entrepreneur", "woman-entrepreneur-logo-biography"].includes(i)
  );

  const isContentDisable = merchantDetail?.tagList?.some(item => item.includes("content-disable-row-image"));
  const merchantTagData = getMerchantTagData(getMerchantTagType(merchantDetail?.tagList));
  const merchantPartners = merchantDetail?.partners;
  const [width] = useWindowSize();

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (isThereAuthErr) {
      window.location.href = `${appConfig.sfLoginUrl}?ReturnUrl=${appConfig.sfBaseUrl}${window?.location?.pathname}`;
    }
  }, [followButtonClicked]);

  // Fetch following status, daily coupon limit.
  const fetchInitialData = async () => {
    const isUserLoggedIn = Boolean(getTokenClient());

    if (!isUserLoggedIn) {
      setIsFollowBtnLoading(false);
      return;
    }

    try {
      const promises = [getIsFollowingMerchant(merchantDetail.id)];

      // If merchant has a coupon, check user daily limit.
      if (merchantDetail.coupon) promises.push(getDailyCouponLimit());

      const result = await Promise.all(promises);
      setFollowingStatus({ ...result[0]?.data?.data });
      setDailyCouponLimit(result[1]?.data?.customerDailyCouponsLimit);
      setCanBeFollowing(true);
    } catch (error) {
      if (error.toString().includes(401)) {
        setIsThereAuthErr(true);
        setCanBeFollowing(false);
      }
    } finally {
      setIsFollowBtnLoading(false);
    }
  };

  const onHoverMerchantTagPopover = () => {
    davinci.clickHoverMerchantTagEvent(merchantTagData?.itemName, merchantPartners);
  };

  const onMerchantTagVisible = () => {
    davinci.viewMerchantTagEvent(merchantTagData?.itemName, merchantPartners);
  };

  const classNameRowContainer = {
    [styles.blankBgRowContainer]: backgroundType === TbackgroundType.blankBg,
    [styles.rowRootColorRowContainer]: backgroundType === TbackgroundType.colorBg,
    [styles.rowRootImgRowContainer]: backgroundType === TbackgroundType.imageBg
  };

  return (
    <div className={classnames(classNameRowContainer)}>
      <RowBackground
        backgroundType={backgroundType}
        isContentDisable={isContentDisable}
        merchantRowBackground={merchantRowBackground}
      />
      <div className={styles.header}>
        <div className={styles.rowContainer}>
          <div className={styles.rowImagesWithTitle}>
            {/* {isWomenEnt && !!merchantDetail?.profilePicture == false && <BlankProfileImage />} */}
            {!!merchantDetail?.logoUrl == false && <BlankProfileImage />}
            <div className={styles.merchant}>
              {merchantDetail?.logoUrl && (
                <section className={classnames(styles.imgContainer, styles.femaleEntpLogo)}>
                  <img src={merchantDetail.logoUrl} alt='group-circle' />
                </section>
              )}
              {/* {isWomenEnt && merchantDetail?.profilePicture && (
      <section className={styles.imgContainer}>
        <img
          className={styles.img}
          src={merchantDetail.profilePicture}
          alt='Women-entrepreneur-profile-picture'
        />
      </section>
    )} */}
              <section className={styles.mainRow}>
                <div
                  className={classnames(styles.head, {
                    [styles.blankProfileImg]: !!merchantDetail?.logoUrl == false
                  })}
                >
                  <div className={styles.title}>
                    <h1 className={styles.merchantName} id='page_title' data-test-id={testIdMappings.h1}>
                      {displayedNameFormatted(merchantDetail.displayedName, width > 511 ? 30 : 15)}
                    </h1>

                    {(!!merchantTagData || !!merchantDetail?.partners) && (
                      <MerchantTag
                        calling='desktop'
                        displayedMerchantName={displayedNameFormatted(
                          merchantDetail.displayedName,
                          width > 511 ? 30 : 15
                        )}
                        eventFunc={onHoverMerchantTagPopover}
                        onMerchantTagVisible={onMerchantTagVisible}
                        hasPartners={!!merchantDetail?.partners}
                        tagData={{ ...merchantTagData, partners: merchantDetail?.partners }}
                      />
                    )}
                    <div className={styles.followBtn}>
                      <FollowButton
                        merchantDetail={merchantDetail}
                        setFollowerCount={setFollowerCount}
                        currentPage={DAVINCI_PAGE_MERCHANT}
                        onClicked={() => setFallowButtonClicked(true)}
                        className={styles.btn}
                      />
                    </div>
                  </div>

                  <div className={styles.footer}>
                    <MerchantRating davinci={davinci} merchantDetail={merchantDetail} />
                    <MerchantFollower followerCount={followerCount} />

                    <div className={styles.merchantSubInfos} id='merchantSubInfos'>
                      <MerchantProducts
                        totalProductCount={!filteredProductCount ? totalProductCount : filteredProductCount}
                      />
                      <MerchantEvaluation numOfEvaluations={merchantDetail?.rating?.feedbackCount} />
                      <WomanEnt isWomenEnt={isWomenEnt} />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <StickyCoupon merchantId={merchantDetail.id} />
        </div>
      </div>
    </div>
  );
}

export default McRow;
